/** @jsx jsx */
import { useContext } from 'react'
import { jsx } from 'theme-ui'
import { ContentfulModule } from '@h/modules'
import { Col, Row } from '@uswitch/trustyle.flex-grid'
import ModuleWrapper, { ModuleWrapperContext } from '@h/module-wrapper'

export default ({ entries = [], options = {} }) => {
  const wrapperContext = useContext(ModuleWrapperContext)
  const defaultSpacing = wrapperContext.alreadyWrapped ? 0 : 'lg'
  const { spacingTop = defaultSpacing, spacingBottom = defaultSpacing } = options

  const rowSx = { variant: 'modules.footerCollection.row' }
  const colSx = { variant: 'modules.footerCollection.column' }

  return (
    <ModuleWrapper spacingTop={spacingTop} spacingBottom={spacingBottom}>
      <Row direction='row' wrap sx={rowSx}>
        <For index='key' each='entry' of={entries}>
          <Choose>
            <When condition={key === 0}>
              <Col span={[12, 6]} key={key} sx={colSx}>
                <ContentfulModule props={{ entity: entry }} />
              </Col>
            </When>
            <Otherwise>
              <Col span={[6, 2]} key={key} sx={colSx}>
                <ContentfulModule props={{ entity: entry }} />
              </Col>
            </Otherwise>
          </Choose>
        </For>
      </Row>
    </ModuleWrapper>
  )
}
